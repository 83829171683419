import React from "react";
import { components } from "../../../../api/spec";
import PSkeleton from "../../../ui/PSkeleton";
import PTitle from "../../../ui/PTitle";
import PErrors from "../../../ui/input/PErrors";
import ReportHeaderCard from "./ReportHeaderCard";
import ReportSummaryCard from "./ReportSummaryCard";
import ReportQuestionSummaryCard from "./ReportQuestionSummaryCard";

type ViewReportScreenProps = {
  report: components["schemas"]["FullReportContentSerializerWrapper"] | null;
  errors: string[];
};

const ViewReportScreenComponent = (props: ViewReportScreenProps) => {
  const { report, errors } = props;

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  if (!report) {
    return (
      <div>
        <div className="px-4 sm:px-0">
          <PTitle title="loading..." />
        </div>
        <div className="flex flex-col gap-5">
          <PSkeleton />
          <PSkeleton />
          <PSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <ReportSummaryCard report={report} />
      <ReportHeaderCard report={report} />
      {report.content.responses.map((response) => (
        <ReportQuestionSummaryCard
          response={response}
          key={response.question.title}
        />
      ))}
    </div>
  );
};

export default ViewReportScreenComponent;
