import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PDescriptionList from "../../../ui/PDescriptionList";
import PTitle from "../../../ui/PTitle";

type ReportHeaderCardProps = {
  report: components["schemas"]["FullReportContentSerializerWrapper"];
};

const ReportHeaderCardComponent = (props: ReportHeaderCardProps) => {
  const { report } = props;

  return (
    <PCard header={<PTitle title="Quiz Details" />}>
      <PDescriptionList
        description="a few high level data points on your quiz to get started"
        entries={[
          {
            key: "Core Question",
            value: report.content.core_question,
          },
          {
            key: "Audience",
            value: report.content.audience,
          },
          {
            key: "# of Respondents",
            value: report.content.num_recipients,
          },
          {
            key: "# of Completed Responses",
            value: report.content.num_responses,
          },
        ]}
      />
    </PCard>
  );
};

export default ReportHeaderCardComponent;
