import React, { useEffect, useState } from "react";
import { useBusyWatcher } from "../../../../util/hooks";
import { components } from "../../../../api/spec";
import { getQuiz, nudgeRecipient } from "../../../../api/reflection/quizzes";
import ViewQuizScreen from "./ViewQuizScreen";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../../ui/layout/PNavbarScreen";

type ViewQuizPageProps = PNavbarScreenProps & {
  quiz: string;
  reportUrl: string;
  shareableUrl: string;
};

const ViewQuizPageComponent = (props: ViewQuizPageProps) => {
  const { quiz, reportUrl, shareableUrl, navbar } = props;

  const [quizContent, setQuizContent] = useState<
    components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"] | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchQuiz = async () => {
    const [response, error] = await busyWatcher(getQuiz({ quiz }));
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    setQuizContent(response);
  };

  const onNudgeClicked = async (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => {
    const [__, error] = await nudgeRecipient(
      {
        recipient: recipient.guid,
      },
      [busyWatcher],
    );
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    await fetchQuiz();
  };

  useEffect(() => {
    fetchQuiz();
  }, []);

  return (
    <PNavbarScreen navbar={navbar}>
      <ViewQuizScreen
        quiz={quizContent}
        errors={errors}
        reportUrl={reportUrl}
        onNudgeClicked={onNudgeClicked}
        shareableUrl={shareableUrl}
      />
    </PNavbarScreen>
  );
};

export default ViewQuizPageComponent;
