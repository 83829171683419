import React, { useEffect, useState } from "react";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../../ui/layout/PNavbarScreen";
import PWizardScreen, {
  PWizardScreenPanel,
} from "../../../ui/layout/PWizardScreen";
import PLoadingScreen from "../../../ui/screens/PLoadingScreen";
import QuizCoreQuestionScreen from "./QuizCoreQuestionScreen";
import QuizAudienceScreen from "./QuizAudienceScreen";
import {
  approveQuiz,
  createQuiz,
  listQuizQuestions,
  setQuizRecipients,
} from "../../../../api/reflection/quizzes";
import { waitForTask } from "../../../../util/tasks";
import { useBusyWatcher } from "../../../../util/hooks";
import QuizQuestionsScreen from "./QuizQuestionsScreen";
import { components } from "../../../../api/spec";
import { Recipient } from "./RecipientEntryRow";
import RecipientEntryScreen from "./RecipientEntryScreen";
import QuizCreationCompletedScreen from "./QuizCreationCompletedScreen";
import QuizConfigurationScreen, {
  QuizConfiguration,
} from "./QuizConfigurationScreen";

enum CreateQuizStage {
  LOADING,
  CORE_QUESTION,
  AUDIENCE,
  CONFIGURATION,
  CREATING_QUIZ,
  REVIEW_QUESTIONS,
  ADD_RECIPIENTS,
  COMPLETED,
}

type CreateQuizPageProps = PNavbarScreenProps & {
  listQuizzesUrl: string;
};

const CreateQuizPageComponent = (props: CreateQuizPageProps) => {
  const { listQuizzesUrl, navbar } = props;

  const [stage, setStage] = useState<CreateQuizStage>(CreateQuizStage.LOADING);
  const [errors, setErrors] = useState<string[]>([]);
  const [coreQuestion, setCoreQuestion] = useState<string>("");
  const [audience, setAudience] = useState<string>("");
  const [config, setConfig] = useState<QuizConfiguration>({
    numQuestions: 10,
  });
  const [quizGuid, setQuizGuid] = useState<string>("");
  const [questions, setQuestions] = useState<
    components["schemas"]["QuizQuestionSummary"][]
  >([]);
  const [quiz, setQuiz] = useState<
    components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"] | null
  >(null);

  const [_, busyWatcher] = useBusyWatcher();

  const goToStage = async (nextStage: CreateQuizStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case,no-empty
    switch (nextStage) {
    }
    setStage(nextStage);
  };

  const onCoreQuestionNextClicked = async (newCoreQuestion: string) => {
    setCoreQuestion(newCoreQuestion);
    await goToStage(CreateQuizStage.AUDIENCE);
  };

  const createAndFetchQuiz = async (newConfig: QuizConfiguration) => {
    setStage(CreateQuizStage.CREATING_QUIZ);

    // First we create the quiz

    const [response, createError] = await createQuiz(
      {
        core_question: coreQuestion,
        quiz_question_count: newConfig.numQuestions,
        audience,
      },
      [busyWatcher],
    );
    if (createError !== null) {
      setErrors([createError.message!]);
      return;
    }
    const newQuizGuid = response!.content.quiz.guid;
    setQuizGuid(newQuizGuid);

    // Now we wait for the task associated with the quiz generation to complete

    const success = await waitForTask(response!.content.task_id, [busyWatcher]);
    if (!success) {
      setErrors(["failed to wait for the task to complete"]);
      return;
    }

    // The quiz has been successfully generated so let's fetch the content

    const [quizQuestions, listError] = await listQuizQuestions(
      { quiz: newQuizGuid },
      [busyWatcher],
    );
    if (listError !== null) {
      setErrors([listError.message!]);
      return;
    }
    setQuestions(quizQuestions!.content!.questions);

    // Now we can continue to the review stage

    await goToStage(CreateQuizStage.REVIEW_QUESTIONS);
  };

  const onAudienceNextClicked = async (newAudience: string) => {
    setAudience(newAudience);
    await goToStage(CreateQuizStage.CONFIGURATION);
  };

  const onConfigurationNextClicked = async (newConfig: QuizConfiguration) => {
    setConfig(newConfig);
    await createAndFetchQuiz(newConfig);
  };

  const onSetRecipientsNextClicked = async (newRecipients: Recipient[]) => {
    const formattedRecipients = [...newRecipients].map((r) => ({
      first_name: r.firstName,
      last_name: r.lastName,
      email: r.emailAddress,
    }));
    const [__, error] = await setQuizRecipients({
      quiz: quizGuid,
      recipients: formattedRecipients,
    });
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    const [quizDetail, quizDetailError] = await approveQuiz({ quiz: quizGuid });
    if (quizDetailError !== null) {
      setErrors([quizDetailError.message!]);
      return;
    }
    setQuiz(quizDetail);
    await goToStage(CreateQuizStage.COMPLETED);
  };

  const getElements = (): PWizardScreenPanel[] => [
    {
      stage: CreateQuizStage.LOADING,
      content: (
        <PLoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: CreateQuizStage.CORE_QUESTION,
      content: (
        <QuizCoreQuestionScreen
          onNextClicked={onCoreQuestionNextClicked}
          errors={errors}
        />
      ),
    },
    {
      stage: CreateQuizStage.AUDIENCE,
      content: (
        <QuizAudienceScreen
          onNextClicked={onAudienceNextClicked}
          errors={errors}
        />
      ),
    },
    {
      stage: CreateQuizStage.CONFIGURATION,
      content: (
        <QuizConfigurationScreen
          config={config}
          minQuestions={5}
          maxQuestions={20}
          onNextClicked={onConfigurationNextClicked}
          onBackClicked={() => goToStage(CreateQuizStage.AUDIENCE)}
        />
      ),
    },
    {
      stage: CreateQuizStage.CREATING_QUIZ,
      content: (
        <PLoadingScreen
          errors={errors}
          message="Creating your quiz now... Give us just a moment as we're doing quite a few things!!"
          onBackClicked={() => goToStage(CreateQuizStage.AUDIENCE)}
        />
      ),
    },
    {
      stage: CreateQuizStage.REVIEW_QUESTIONS,
      content: (
        <QuizQuestionsScreen
          questions={questions}
          onNextClicked={() => goToStage(CreateQuizStage.ADD_RECIPIENTS)}
        />
      ),
    },
    {
      stage: CreateQuizStage.ADD_RECIPIENTS,
      content: (
        <RecipientEntryScreen
          maxRecipients={10}
          onNextClicked={onSetRecipientsNextClicked}
          onBackClicked={() => goToStage(CreateQuizStage.REVIEW_QUESTIONS)}
        />
      ),
    },
    {
      stage: CreateQuizStage.COMPLETED,
      content: quiz && (
        <QuizCreationCompletedScreen
          title={quiz.content.title!}
          description={quiz.content.description!}
          recipients={quiz.content.recipients.map((r) => ({
            firstName: r.first_name!,
            lastName: r.last_name!,
            emailAddress: r.email!,
          }))}
          onNextClicked={() => {
            window.location.href = listQuizzesUrl;
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(CreateQuizStage.CORE_QUESTION);
  }, []);

  return (
    <PNavbarScreen
      navbar={{
        ...navbar,
        forceLoading: stage === CreateQuizStage.CREATING_QUIZ,
      }}
    >
      <PWizardScreen elements={getElements()} stage={stage} />
    </PNavbarScreen>
  );
};

export default CreateQuizPageComponent;
