import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

// eslint-disable-next-line import/prefer-default-export
export const getQuizReport = async (
  request: operations["reflection_api_quizzes_get_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["FullReportContentSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/reports/get/")
      .method("get")
      .create()(request),
  );
