import React from "react";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { CommonWellButtonProps } from "./common";
import PIconButton from "./PIconButton";

const PIconDataDocumentButtonComponent = (props: CommonWellButtonProps) => (
  <PIconButton {...props} icon={<DocumentChartBarIcon />} />
);

PIconDataDocumentButtonComponent.defaultProps = {
  disabled: false,
};

export default PIconDataDocumentButtonComponent;
