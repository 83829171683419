import React, { ChangeEvent, useEffect, useState } from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PRangeSlider from "../../../ui/input/PRangeSlider";

export type QuizConfiguration = {
  numQuestions: number;
};

type QuizConfigurationScreenProps = {
  config: QuizConfiguration;
  minQuestions: number;
  maxQuestions: number;
  onNextClicked: (config: QuizConfiguration) => void;
  onBackClicked: () => void;
};

const QuizConfigurationScreenComponent = (
  props: QuizConfigurationScreenProps,
) => {
  const {
    config: configInput,
    minQuestions,
    maxQuestions,
    onNextClicked,
    onBackClicked,
  } = props;

  const [config, setConfig] = useState<QuizConfiguration>(configInput);
  const [busy, _] = useBusyWatcher();

  const onNumQuestionsChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const numQuestions = parseInt(event.target.value, 10);
    setConfig({
      ...config!,
      numQuestions,
    });
  };

  useEffect(() => {
    setConfig(configInput);
  }, []);

  return (
    <PFormScreen
      title="Configure Your Quiz"
      comment="Just a few quick settings so that we can tailor the quiz to your needs"
      onNextClicked={() => onNextClicked(config!)}
      nextDisabled={!config || busy}
      onBackClicked={onBackClicked}
      backDisabled={busy}
    >
      <PRangeSlider
        label="# of Questions"
        min={minQuestions}
        max={maxQuestions}
        value={config.numQuestions}
        onChange={onNumQuestionsChanged}
        disabled={busy}
        required
      />
    </PFormScreen>
  );
};

export default QuizConfigurationScreenComponent;
