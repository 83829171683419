import React, { useState } from "react";
import RecipientEntryRow, { Recipient } from "./RecipientEntryRow";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PPlusButton from "../../../ui/buttons/PPlusButton";

type RecipientEntryScreenProps = {
  maxRecipients: number;
  onNextClicked: (recipients: Recipient[]) => void;
  onBackClicked: () => void;
};

const RecipientEntryScreenComponent = (props: RecipientEntryScreenProps) => {
  const {
    maxRecipients,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [numRecipients, setNumRecipients] = useState<number>(1);
  const [recipients, setRecipients] = useState<(Recipient | null)[]>([null]);
  const [busy, _] = useBusyWatcher();

  const onRecipientUpdated = (index: number, recipient: Recipient | null) => {
    const newRecipients = [...recipients];
    newRecipients[index] = recipient;
    setRecipients(newRecipients);
  };

  const onAddRecipientClicked = () => {
    if (numRecipients >= maxRecipients) {
      return;
    }
    setNumRecipients(numRecipients + 1);
    setRecipients([...recipients, null]);
  };

  const onRemoveRecipientClicked = (index: number) => {
    setNumRecipients(numRecipients - 1);
    setRecipients(recipients.filter((__, i) => i !== index));
  };

  const allRecipientsValid = recipients.every((r) => r !== null);

  const onNextClicked = () => {
    onNextClickedInput(recipients as Recipient[]);
  };

  const getRecipientEntryRow = (index: number, includeRemove: boolean) => (
    <RecipientEntryRow
      key={index}
      index={index}
      onEntryUpdated={(r) => onRecipientUpdated(index, r)}
      onRemoveClicked={
        includeRemove ? () => onRemoveRecipientClicked(index) : null
      }
    />
  );

  const getRecipientEntryRows = () => {
    const toReturn = [];
    for (let i = 0; i < numRecipients; i += 1) {
      toReturn.push(getRecipientEntryRow(i, numRecipients > 1));
    }
    return toReturn;
  };

  return (
    <PFormScreen
      title="Add Recipients"
      comment="Who would you like to send this quiz to? You'll be able to add more people after this, but you'll need to specify at least one now."
      onBackClicked={onBackClicked}
      backDisabled={busy}
      onNextClicked={onNextClicked}
      nextDisabled={!allRecipientsValid || busy}
    >
      <div className="flex flex-col gap-3">
        {getRecipientEntryRows()}
        {numRecipients >= maxRecipients && (
          <div className="italic text-sm text-center">
            You've reached the maximum number of recipients
          </div>
        )}
        <PPlusButton
          kind="primary"
          onClick={onAddRecipientClicked}
          disabled={busy || numRecipients >= maxRecipients}
          text="add recipient"
        />
      </div>
    </PFormScreen>
  );
};

export default RecipientEntryScreenComponent;
