import React, { ReactNode } from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import PSubtitle from "../../../ui/PSubtitle";
import MultipleChoiceBody from "./MultipleChoiceBody";
import YesNoBody from "./YesNoBody";
import TextBody from "./TextBody";
import OpinionScaleBody from "./OpinionScaleBody";

type ReportQuestionSummaryCardProps = {
  response: components["schemas"]["CommonQuizQuestionContent"];
};

const ReportQuestionSummaryCardComponent = (
  props: ReportQuestionSummaryCardProps,
) => {
  const { response } = props;

  const getBody = (): ReactNode => {
    switch (response.question.question_type) {
      case "multiple_choice":
        return (
          <MultipleChoiceBody content={response.multiple_choice_summary!} />
        );
      case "yes_no":
        return <YesNoBody content={response.yes_no_summary!} />;
      case "long_text":
        return <TextBody content={response.text_summary!} />;
      case "short_text":
        return <TextBody content={response.text_summary!} />;
      case "opinion_scale":
        return <OpinionScaleBody content={response.opinion_scale_summary!} />;
      default:
        return (
          <pre className="text-xs">{JSON.stringify(response, null, 2)}</pre>
        );
    }
  };

  return (
    <PCard
      header={
        <div>
          <PTitle title={response.question.title} />
          {response.question.description ? (
            <PSubtitle title={response.question.description} />
          ) : null}
        </div>
      }
    >
      <div className="flex flex-col gap-3">{getBody()}</div>
    </PCard>
  );
};

export default ReportQuestionSummaryCardComponent;
