import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";

type QuizCompletionScreenProps = {
  recipientName: string;
};

const QuizCompletionScreenComponent = (props: QuizCompletionScreenProps) => {
  const { recipientName } = props;

  return (
    <PFormScreen
      title={`Thank you ${recipientName}!`}
      nextText="Close"
      onNextClicked={() => window.close()}
    >
      <div>you're all done! click the button below to close this window.</div>
    </PFormScreen>
  );
};

export default QuizCompletionScreenComponent;
