import React from "react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { CommonWellButtonProps } from "./common";
import PIconButton from "./PIconButton";

const PIconLinkButtonComponent = (props: CommonWellButtonProps) => (
  <PIconButton {...props} icon={<LinkIcon />} />
);

PIconLinkButtonComponent.defaultProps = {
  disabled: false,
};

export default PIconLinkButtonComponent;
