import React, { useEffect, useState } from "react";
import PWizardScreen, {
  PWizardScreenPanel,
} from "../../../ui/layout/PWizardScreen";
import PLoadingScreen from "../../../ui/screens/PLoadingScreen";
import { components } from "../../../../api/spec";
import { useBusyWatcher } from "../../../../util/hooks";
import {
  getQuizRecipient,
  markRecipientAsOpened,
} from "../../../../api/reflection/quizzes";
import QuizNotFoundScreen from "./QuizNotFoundScreen";
import QuizAlreadyCompletedScreen from "./QuizAlreadyCompletedScreen";
import QuizWelcomeScreen from "./QuizWelcomeScreen";
import QuizCompletionScreen from "./QuizCompletionScreen";
import EmbedTypeformScreen from "./EmbedTypeformScreen";

type EmbedTypeformPageProps = {
  recipient: string;
};

enum EmbedTypeformStage {
  LOADING,
  WELCOME,
  RESPOND,
  COMPLETED,
  ALREADY_COMPLETED,
  NOT_FOUND,
}

const EmbedTypeformPage = (props: EmbedTypeformPageProps) => {
  const { recipient } = props;

  const [recipientContent, setRecipientContent] = useState<
    components["schemas"]["GetRecipientResponseSerializerWrapper"] | null
  >(null);
  const [stage, setStage] = useState<EmbedTypeformStage>(
    EmbedTypeformStage.LOADING,
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchRecipient = async (): Promise<
    [
      components["schemas"]["GetRecipientResponseSerializerWrapper"] | null,
      components["schemas"]["BadInputResponseWrapper"] | null,
    ]
  > => {
    const [newRecipient, newErrors] = await getQuizRecipient({ recipient }, [
      busyWatcher,
    ]);
    if (newErrors !== null) {
      setErrors([newErrors.message!]);
    }
    if (newRecipient !== null) {
      setRecipientContent(newRecipient);
    }
    return [newRecipient, newErrors];
  };

  const goToStage = async (nextStage: EmbedTypeformStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case,no-empty
    switch (nextStage) {
      case EmbedTypeformStage.WELCOME:
        await markRecipientAsOpened({ recipient }, [busyWatcher]);
    }
    setStage(nextStage);
  };

  const getRecipientName = (): string | null => {
    if (recipientContent === null) {
      return null;
    }
    return `${recipientContent.content.first_name} ${recipientContent.content.last_name}`.trim();
  };

  const getSenderName = (): string | null => {
    if (recipientContent === null) {
      return null;
    }
    return `${recipientContent.content.sender!.first_name} ${recipientContent.content.sender!.last_name}`.trim();
  };

  const loadPage = async () => {
    const [newRecipient, __] = await fetchRecipient();
    if (newRecipient === null) {
      return;
    }
    if (!newRecipient.content.does_quiz_exist) {
      await goToStage(EmbedTypeformStage.NOT_FOUND);
      return;
    }
    if (newRecipient.content.is_response_complete) {
      await goToStage(EmbedTypeformStage.ALREADY_COMPLETED);
      return;
    }
    await goToStage(EmbedTypeformStage.WELCOME);
  };

  const getElements = (): PWizardScreenPanel[] => [
    {
      stage: EmbedTypeformStage.LOADING,
      content: (
        <PLoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: EmbedTypeformStage.NOT_FOUND,
      content: recipientContent && (
        <QuizNotFoundScreen recipientName={getRecipientName()!} />
      ),
    },
    {
      stage: EmbedTypeformStage.ALREADY_COMPLETED,
      content: recipientContent &&
        recipientContent.content &&
        recipientContent.content.quiz !== null && (
          <QuizAlreadyCompletedScreen
            recipientName={getRecipientName()!}
            quizTitle={recipientContent.content.quiz.title!}
          />
        ),
    },
    {
      stage: EmbedTypeformStage.WELCOME,
      content: recipientContent &&
        recipientContent.content &&
        recipientContent.content.quiz !== null && (
          <QuizWelcomeScreen
            recipientName={getRecipientName()!}
            senderName={getSenderName()!}
            quizTitle={recipientContent.content.quiz.title!}
            quizDescription={recipientContent.content.quiz.description!}
            timeEstimate={recipientContent.content.time_estimate!}
            onNextClicked={() => goToStage(EmbedTypeformStage.RESPOND)}
          />
        ),
    },
    {
      stage: EmbedTypeformStage.RESPOND,
      content: recipientContent &&
        recipientContent.content &&
        recipientContent.content.quiz !== null && (
          <EmbedTypeformScreen
            typeformId={recipientContent.content.quiz.typeform_id!}
            recipient={recipient}
            onFormCompleted={() => goToStage(EmbedTypeformStage.COMPLETED)}
          />
        ),
    },
    {
      stage: EmbedTypeformStage.COMPLETED,
      content: recipientContent && (
        <QuizCompletionScreen recipientName={getRecipientName()!} />
      ),
    },
  ];

  useEffect(() => {
    loadPage();
  }, []);

  return <PWizardScreen elements={getElements()} stage={stage} />;
};

export default EmbedTypeformPage;
