import React from "react";
import PCard from "../../ui/PCard";
import { components } from "../../../api/spec";
import PBadge from "../../ui/PBadge";

type QuizQuestionCardProps = {
  question: components["schemas"]["QuizQuestionSummary"];
  index: number;
};

const QuizQuestionCardComponent = (props: QuizQuestionCardProps) => {
  const { question, index } = props;

  const titleFromType = (
    type: components["schemas"]["QuestionTypeEnum"],
  ): string => {
    switch (type) {
      case "multiple_choice":
        return "Multiple Choice";
      case "short_text":
        return "Short Text";
      case "opinion_scale":
        return "Scale 1-10";
      case "long_text":
        return "Long Text";
      case "yes_no":
        return "Yes / No";
      default:
        throw new Error(`Unknown question type: ${type}`);
    }
  };

  return (
    <PCard>
      <div className="grid grid-cols-12">
        <div className="col-span-1 font-bold self-start">{index + 1}.</div>
        <div className="col-span-11 flex flex-col gap-3">
          <div className="flex flex-row gap-3 justify-between items-center">
            <div className="font-bold">{question.title}</div>
            <PBadge
              className="bg-p-black-lightest"
              content={titleFromType(question.question_type)}
            />
          </div>
          <div>{question.description}</div>
          {question.choices ? (
            <ul className="list-disc list-inside">
              {question.choices.map((choice) => (
                <li key={choice}>{choice}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </PCard>
  );
};

export default QuizQuestionCardComponent;
