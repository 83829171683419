import React, { ReactNode } from "react";
import { components } from "../../../../api/spec";
import PTimeAgo from "../../../ui/PTimeAgo";
import PSendButton from "../../../ui/buttons/PSendButton";
import { useBusyWatcher } from "../../../../util/hooks";

type QuizRecipientSummaryRowProps = {
  recipient: components["schemas"]["ReflectionQuizRecipientSummary"];
  onNudgeClicked: () => void;
};

const QuizRecipientSummaryRowComponent = (
  props: QuizRecipientSummaryRowProps,
) => {
  const { recipient, onNudgeClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getStatus = (): ReactNode => {
    let message: ReactNode;
    let timeAgo: ReactNode;
    if (recipient.time_response_completed) {
      message = <span className="font-semibold">Submitted</span>;
      timeAgo = <PTimeAgo date={new Date(recipient.time_response_completed)} />;
    } else if (recipient.time_first_opened) {
      message = <span>Opened</span>;
      timeAgo = <PTimeAgo date={new Date(recipient.time_first_opened)} />;
    } else if (recipient.time_first_email_sent) {
      message = <span>Sent</span>;
      timeAgo = <PTimeAgo date={new Date(recipient.time_first_email_sent)} />;
    } else {
      message = "Unknown";
    }
    if (!timeAgo) {
      return message;
    }
    return (
      <div className="flex flex-row gap-1 items-center">
        {message}
        {timeAgo}
      </div>
    );
  };

  return (
    <tr
      key={recipient.email}
      className={
        recipient.is_response_complete ? "bg-success-lightest" : undefined
      }
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-p-black sm:pl-0">
        {recipient.first_name} {recipient.last_name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        {recipient.email}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        {getStatus()}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        {recipient.time_last_nudged ? (
          <PTimeAgo date={new Date(recipient.time_last_nudged)} />
        ) : (
          "-"
        )}
      </td>
      <td
        aria-label={`nudge ${recipient.guid}`}
        className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0 text-p-black-lighter"
      >
        {!recipient.is_response_complete ? (
          <PSendButton
            kind="primary"
            disabled={busy || !recipient.nudgeable.can_nudge}
            onClick={onNudgeClicked}
            disabledTooltip={recipient.nudgeable.cant_nudge_reason}
            text="nudge"
          />
        ) : (
          <span className="font-semibold">Completed</span>
        )}
      </td>
    </tr>
  );
};

export default QuizRecipientSummaryRowComponent;
