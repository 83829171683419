import React from "react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import PTitle from "../../../ui/PTitle";
import { components } from "../../../../api/spec";
import PSkeleton from "../../../ui/PSkeleton";
import PSubtitle from "../../../ui/PSubtitle";
import PBadge from "../../../ui/PBadge";
import { classNames } from "../../../../util/strings";
import QuizQuestionCard from "../QuizQuestionCard";
import PErrors from "../../../ui/input/PErrors";
import PButton from "../../../ui/buttons/PButton";
import QuizRecipientSummaryRow from "./QuizRecipientSummaryRow";
import { useBusyWatcher } from "../../../../util/hooks";
import { getPathAtCurrentUrl } from "../../../../util/url";

type ViewQuizScreenProps = {
  quiz:
    | components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"]
    | null;
  onNudgeClicked: (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => void;
  reportUrl: string;
  shareableUrl: string;
  errors: string[];
};

const ViewQuizScreenComponent = (props: ViewQuizScreenProps) => {
  const { quiz, onNudgeClicked, reportUrl, errors, shareableUrl } = props;

  const [busy, _] = useBusyWatcher();

  const onCopyLinkClicked = async () => {
    const shareableLink = `${shareableUrl}?quiz=${quiz!.content.guid}`;
    const fullUrl = getPathAtCurrentUrl(shareableLink);
    await navigator.clipboard.writeText(fullUrl);
    toast.success("Link copied to clipboard");
  };

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  if (!quiz) {
    return (
      <div>
        <div className="px-4 sm:px-0">
          <PTitle title="loading..." />
        </div>
        <div className="flex flex-col gap-5">
          <PSkeleton />
          <PSkeleton />
          <PSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="px-4 sm:px-0">
        <div className="flex flex-row gap-3 items-center">
          <PTitle title={quiz.content.title!} />
          <PBadge
            content={quiz.content.is_completed ? "Completed" : "In Progress"}
            className={classNames(
              "text-p-black-lighter font-semibold",
              quiz.content.is_completed ? "bg-success" : "bg-primary",
            )}
          />
        </div>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-p-black-lighter">
          {quiz.content.description}
        </p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="border-t border-p-black-lightest px-4 py-6 sm:col-span-1 sm:px-0">
            <dt>
              <PSubtitle title="Core Question" />
            </dt>
            <dd className="mt-1 text-sm leading-6 text-p-black-lighter sm:mt-2">
              {quiz.content.core_question}
            </dd>
          </div>
          <div className="border-t border-p-black-lightest px-4 py-6 sm:col-span-1 sm:px-0">
            <dt>
              <PSubtitle title="Audience" />
            </dt>
            <dd className="mt-1 text-sm leading-6 text-p-black-lighter sm:mt-2">
              {quiz.content.audience}
            </dd>
          </div>
          <div className="border-t border-p-black-lightest px-4 py-6 sm:col-span-1 sm:px-0">
            <dt>
              <PSubtitle title="Status" />
            </dt>
            <dd className="mt-1 text-sm leading-6 text-p-black-lighter sm:mt-2">
              <span className="font-bold">{quiz.content.recipient_count}</span>{" "}
              emails sent,{" "}
              <span className="font-bold">
                {quiz.content.opened_recipient_count}
              </span>{" "}
              quizzes opened,{" "}
              <span className="font-bold">
                {quiz.content.completed_recipient_count}
              </span>{" "}
              quizzes completed
            </dd>
          </div>
          <div className="border-t border-p-black-lightest px-4 py-6 sm:col-span-1 sm:px-0">
            <dt>
              <PSubtitle title="Actions" />
            </dt>
            <dd className="mt-1 text-sm leading-6 text-p-black-lighter sm:mt-2 flex flex-row gap-2">
              <PButton
                kind="primary"
                icon={<LinkIcon className="w-5 h-5" />}
                disabled={busy || !quiz || quiz.content.is_completed}
                onClick={onCopyLinkClicked}
                disabledTooltip={
                  quiz?.content.is_completed ? "Quiz is completed" : null
                }
              >
                copy shareable link
              </PButton>
              <PButton
                kind="primary"
                href={`${reportUrl}?quiz=${quiz.content.guid}`}
                disabled={!quiz.content.is_report_available}
                disabledTooltip="Report will be available when quiz is completed"
              >
                view report
              </PButton>
            </dd>
          </div>
          <div className="border-t border-p-black-lightest px-4 py-6 sm:col-span-2 sm:px-0">
            <dt>
              <PSubtitle title="Recipients" />
            </dt>
            <dd>
              <div className="flow-root mt-1 sm:mt-2">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-p-black-lightest">
                      <thead className="bg-p-black-lightest">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-p-black sm:pl-0"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-p-black"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-p-black"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-p-black"
                          >
                            Last Nudged At
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-p-black-lightest">
                        {quiz.content.recipients.map((recipient) => (
                          <QuizRecipientSummaryRow
                            key={recipient.guid}
                            recipient={recipient}
                            onNudgeClicked={() => onNudgeClicked(recipient)}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </dd>
          </div>
          <div className="border-t border-p-black-lightest px-4 py-6 sm:col-span-2 sm:px-0">
            <dt>
              <PSubtitle
                title={`Questions (${quiz.content.question_count} total)`}
              />
            </dt>
            <dd className="mt-2 text-sm text-p-black">
              {quiz.content.questions ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                  {quiz.content.questions.map((question, index) => (
                    <QuizQuestionCard
                      key={question.title}
                      question={question}
                      index={index}
                    />
                  ))}
                </div>
              ) : (
                <div className="italic">
                  No questions found in association with this quiz
                </div>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ViewQuizScreenComponent;
