import { components } from "../../../../api/spec";

// eslint-disable-next-line import/prefer-default-export
export const bgColorFromImportance = (
  importance: components["schemas"]["ImportanceEnum"],
): string => {
  switch (importance) {
    case "critical":
      return "bg-purple-900";
    case "high":
      return "bg-danger";
    case "medium":
      return "bg-warning";
    case "low":
      return "bg-success";
    default:
      throw new Error(`Unknown importance: ${importance}`);
  }
};
