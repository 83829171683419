import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import PBadge from "../../../ui/PBadge";
import { classNames } from "../../../../util/strings";
import { bgColorFromImportance } from "./common";

type ReportSummaryCardProps = {
  report: components["schemas"]["FullReportContentSerializerWrapper"];
};

const ReportSummaryCardComponent = (props: ReportSummaryCardProps) => {
  const { report } = props;

  return (
    <PCard header={<PTitle title="Executive Summary" />}>
      <div className="flex flex-col gap-3">
        <div>
          <PTitle title="Takeaways" />
          <ul className="list-disc ml-5 space-y-4 my-4">
            {report.content.summary.takeaways.map((takeaway) => (
              <li key={takeaway.takeaway}>
                <div className="flex flex-row items-center gap-2">
                  <PBadge
                    content={takeaway.importance}
                    className={classNames(
                      "min-w-20 text-p-white",
                      bgColorFromImportance(takeaway.importance),
                    )}
                  />
                  <div>{takeaway.takeaway}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <PTitle title="Executive Summary" />
          <div className="my-4">{report.content.summary.executive_summary}</div>
        </div>
        <dl className="divide-y divide-p-black-lightest">
          {report.content.summary.sections.map((section) => (
            <div
              key={section.title}
              className="lg:grid lg:grid-cols-12 lg:gap-8 py-4"
            >
              <PTitle title={section.title} className="lg:col-span-5" />
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7">{section.content}</p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </PCard>
  );
};

export default ReportSummaryCardComponent;
