import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";

type QuizWelcomeScreenProps = {
  recipientName: string;
  senderName: string;
  quizTitle: string;
  quizDescription: string;
  timeEstimate: string;
  onNextClicked: () => void;
};

const QuizWelcomeScreenComponent = (props: QuizWelcomeScreenProps) => {
  const {
    recipientName,
    senderName,
    quizTitle,
    quizDescription,
    timeEstimate,
    onNextClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <PFormScreen
      title={`Welcome ${recipientName}`}
      comment="thanks so much for coming!"
      nextText="Start Quiz"
      onNextClicked={onNextClicked}
      nextDisabled={busy}
    >
      <div>
        <span className="font-semibold">{senderName}</span> asked us to send you
        this quiz:
      </div>
      <PCard>
        <div className="flex flex-col gap-3">
          <PTitle title={quizTitle} />
          <p>{quizDescription}</p>
        </div>
      </PCard>
      <div>
        this quiz should take approximately{" "}
        <span className="font-semibold">{timeEstimate}</span>.
      </div>
    </PFormScreen>
  );
};

export default QuizWelcomeScreenComponent;
