import React from "react";
import { classNames } from "../../util/strings";

export type ListEntry = {
  key: string;
  value?: any | null;
};

type PDescriptionListProps = {
  title?: string | null;
  description?: string | null;
  entries: ListEntry[];
};

const PDescriptionListComponent = (props: PDescriptionListProps) => {
  const { title, description, entries } = props;

  return (
    <div>
      <div className="px-4 sm:px-0">
        {title ? (
          <h3 className="text-base font-semibold leading-7 text-p-black-lighter">
            {title}
          </h3>
        ) : null}
        {description ? (
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            {description}
          </p>
        ) : null}
      </div>
      <div className="mt-6 border-t border-p-black-lightest">
        <dl className="divide-y divide-p-black-lightest">
          {entries.map((entry, index) => (
            <div
              key={entry.key}
              className={classNames(
                "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3",
                index % 2 === 0 ? "bg-p-black-lightest/5" : "bg-p-white",
              )}
            >
              <dt className="text-sm font-medium leading-6 text-p-black-lighter">
                {entry.key}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                {entry.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

PDescriptionListComponent.defaultProps = {
  title: null,
  description: null,
};

export default PDescriptionListComponent;
