import React from "react";
import { components } from "../../../../api/spec";
import PTitle from "../../../ui/PTitle";
import PBadge from "../../../ui/PBadge";
import { classNames } from "../../../../util/strings";
import { bgColorFromImportance } from "./common";

type TextBodyProps = {
  content: components["schemas"]["TextResponsesSummary"];
};

const TextBodyComponent = (props: TextBodyProps) => {
  const { content } = props;

  return (
    <div className="flex flex-col gap-3">
      {content.takeaways.length > 0 ? (
        <div>
          <PTitle title="Takeaways" />
          <ul className="list-disc ml-5 space-y-4 my-4">
            {content.takeaways.map((takeaway) => (
              <li key={takeaway.takeaway}>
                <div className="flex flex-row items-center gap-2">
                  <PBadge
                    content={takeaway.importance}
                    className={classNames(
                      "min-w-20 text-p-white",
                      bgColorFromImportance(takeaway.importance),
                    )}
                  />
                  <div>{takeaway.takeaway}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {content.executive_summary ? (
        <div>
          <PTitle title="Summary" />
          <div className="my-4">{content.executive_summary}</div>
        </div>
      ) : null}
      {content.quotes && content.quotes.length > 0 ? (
        <div className="flex flex-col gap-3">
          <PTitle title="Quotes" />
          {content.quotes.map((quote) => (
            <div className="p-2 rounded-lg bg-p-black/5 text-sm italic">
              {quote.quote}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default TextBodyComponent;
