import React from "react";
import { toast } from "react-toastify";
import { components } from "../../../../api/spec";
import { clickableText } from "../../../../util/style";
import PIconDataDocumentButton from "../../../ui/buttons/PIconDataDocumentButton";
import PIconLinkButton from "../../../ui/buttons/PIconLinkButton";
import { getPathAtCurrentUrl } from "../../../../util/url";

type QuizSummaryRowProps = {
  quiz: components["schemas"]["ReflectionQuizSummary"];
  viewUrl: string;
  reportUrl: string;
  shareableUrl: string;
};

const QuizSummaryRowComponent = (props: QuizSummaryRowProps) => {
  const { quiz, viewUrl, reportUrl, shareableUrl } = props;

  const onShareableLinkClicked = async () => {
    const shareableLink = `${shareableUrl}?quiz=${quiz.guid}`;
    const fullUrl = getPathAtCurrentUrl(shareableLink);
    await navigator.clipboard.writeText(fullUrl);
    toast.success("Link copied to clipboard");
  };

  return (
    <tr key={quiz.guid}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-p-black sm:pl-6">
        {quiz.title}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        {quiz.time_created}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        {quiz.completed_recipient_count} / {quiz.recipient_count}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        {quiz.is_completed ? "Yes" : "No"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter">
        <div className="flex flex-row items-start justify-start">
          {quiz.is_report_available ? (
            <PIconDataDocumentButton
              href={`${reportUrl}?quiz=${quiz.guid}`}
              tooltip="view report"
              className="text-success"
            />
          ) : (
            <span />
          )}
        </div>
      </td>
      <td
        aria-label="copy shareable link"
        className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter"
      >
        <div className="flex flex-row items-start justify-start">
          {!quiz.is_completed ? (
            <PIconLinkButton
              tooltip="copy shareable link"
              onClick={onShareableLinkClicked}
            />
          ) : (
            <span />
          )}
        </div>
      </td>
      <td
        aria-label="View Quiz"
        className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
      >
        <div className="flex flex-row gap-2 items-center">
          <a href={`${viewUrl}?quiz=${quiz.guid}`} className={clickableText}>
            view
          </a>
        </div>
      </td>
    </tr>
  );
};

export default QuizSummaryRowComponent;
