import React, { forwardRef, useState } from "react";
import { useUniqueId } from "../../../util/hooks";
import PLabel from "./PLabel";
import PErrors from "./PErrors";
import { classNames } from "../../../util/strings";

type PRangeSliderProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string | null;
  errors?: string[] | null;
};

const PRangeSliderComponent = forwardRef<HTMLInputElement, PRangeSliderProps>(
  ({ label, required, errors, value: valueInput, ...props }, ref) => {
    const labelId = useUniqueId();
    const inError = errors ? errors.length > 0 : false;
    const [value, setValue] = useState<number | null>(valueInput as number);

    return (
      <div className="w-full">
        {label ? (
          <PLabel
            required={required}
            htmlFor={labelId}
            error={inError}
            label={label}
          />
        ) : null}
        <div className="flex flex-row items-center">
          <input
            id={labelId}
            required={required}
            type="range"
            className={classNames(
              "w-full h-2 bg-p-black-lightest rounded-lg appearance-none cursor-pointer",
              "slider-thumb:bg-primary slider-thumb:border-2 slider-thumb:border-primary-lighter",
              "slider-range:bg-primary slider-range:h-2 slider-range:rounded-full slider-thumb:h-3",
              "slider-thumb:w-3",
            )}
            {...props}
            ref={ref}
            value={value ?? undefined}
            onChange={(event) => {
              setValue(parseInt(event.target.value, 10));
              if (props.onChange) props.onChange(event);
            }}
          />
          <div className="w-8 text-end">{value}</div>
        </div>
        <PErrors errors={errors ?? []} />
      </div>
    );
  },
);

PRangeSliderComponent.defaultProps = {
  label: null,
  errors: null,
};

export default PRangeSliderComponent;
