import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { components } from "../../../../api/spec";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type YesNoBodyProps = {
  content: components["schemas"]["YesNoResponsesSummary"];
};

const YesNoBodyComponent = (props: YesNoBodyProps) => {
  const { content } = props;

  const data = useMemo(
    () => ({
      labels: ["Yes", "No"],
      datasets: [
        {
          label: "# of Responses",
          data: [content.yes_count, content.no_count],
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    }),
    [],
  );

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default YesNoBodyComponent;
